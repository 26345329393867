import React from 'react';
import { above, below } from 'src/components/global/mediaqueries';
import { Above, Below } from 'src/components/Breakpoints';
import { useStaticQuery, graphql } from 'gatsby';
import Arrow from 'src/icons/Arrow';
import Button from 'src/components/Button';
import CrossButton from 'src/components/buttons/CrossButton';
import HeaderLink from './HeaderLink';
import inBrowser from 'src/utils/inBrowser';
import IsLoggedIn from 'src/utils/isLoggedIn';
import Link from 'src/components/Link';
import Modules from 'src/load-module';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import Text from 'src/components/Text';
import theme from 'src/styles/theme';
import useAuth from 'src/hooks/useAuth';

const NavigationWrapper = styled('div')`
    ${below.tablet_s} {
        width: 100%;
        max-height: 0;
        transform: translateY(-100%);
        left: 0;
        top: 0;
        opacity: 0;
        z-index: 900;
        transition: transform 0.2s, max-height 0s;

        &.open {
            transform: translateY(0);
            max-height: 100vh;
            opacity: 1;
        }
    }

    ${above.tablet_s} {
        height: 100%;
        width: 100%;
    }
`;

const LinksWrapper = styled('div')`
    ${below.tablet_s} {
        padding: 24px 12px 40px 12px;
        display: none;
        background-color: ${theme.color.white};

        &.open {
            display: block;
            width: 100%;
        }
    }

    ${above.tablet_s} {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
    }
`;

const LogoutButton = styled(Button)`
    margin-left: 12px;
`;

const MyPageWrapper = styled('div')`
    ${below.tablet_s} {
        padding-top: 24px;
        border-top: 1px solid ${theme.color.black};
        display: block;
        width: 100%;

        &.subMenuClosed{
            display: none;
        }
    }

    ${above.tablet_s} {
        display: none;
    }
`;

const SubMenuWrapper = styled('div')`
    ${above.tablet_s} {
        position: absolute;
        width: 100%;
        left: 0;

        &.open {
            top: 52px;
            height: 100%;
        }
    }
`;

const SubMenuLinkWrapper = styled('div')`
    ${above.tablet_s} {
        width: ${({ maxWidth }) => maxWidth ? 'calc(33.33% - 20px)' : '100%'};
    }

    ${above.desktop_s} {
        padding-top: 16px;
        padding-bottom: 48px;
        width: ${({ maxWidth }) => maxWidth ? '16.66%' : '100%'};
        margin-right: 20px;
    }
`;

const StyledLink = styled(Link)`
    color: ${theme.color.black};
    line-height: 1;
    font-weight: 400;
    border-bottom: 1px solid transparent;
    font-family: 'Tiempos';
    font-size: 24px;

    &.show-count{    
        &:hover {
            &::after {
                content: '${props => props.count}';
                font-size: 12px;
                margin-left: 5px;
                vertical-align: top;
            }
        }
    }
`;

const StyledLinkWrapper = styled('div')`
    margin-bottom: 24px;

    ${above.tablet_s} {
        margin-bottom: 16px;
    }

    ${above.desktop_s} {
        margin-bottom: 20px;
    }
`;

const BackButton = styled('div')`
    cursor: pointer;

    ${below.tablet_s} {
        display: flex;
        border-bottom: solid 1px ${theme.color.black};
        margin-bottom: 24px;
    }

    ${above.tablet_s} {
        position: absolute;
        top: 24px;
        right: 24px;
        transform: rotate(45deg);
    }

    ${above.desktop_s} {
        top: 32px;
        right: 32px;
    }
`;

const StyledBelow = styled(Below)`
    margin-top: 24px;
    margin-bottom: 24px;
    display: flex;
    align-items: center;
`;

const StyledArrow = styled(Arrow)`
    transform: rotate(180deg);
`;

const BackButtonText = styled(Text)`
    font-family: 'Tiempos';
    margin-left: 16px;
    font-size: 24px;
    letter-spacing: 0.02em;
`;

const HeaderLinkWrapper = styled('div')`
    ${below.tablet_s} {
        margin-bottom: 24px;

        &.subMenuClosed{
            display: none;
        }
    }
`;

const MainMenuWrapper = styled('div')`
    ${above.tablet_s}{
        height: 100%;
        margin: auto;
    }
`;

const SubMenuContent = styled('div')`
    background-color: ${theme.color.white};

    ${below.tablet_s} {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        padding: 0px 12px 0px 12px;
        overflow-y: auto;
        overflow-x: hidden;

        &.open {
            height: 100%;
        }
    }

    ${above.tablet_s} {
        &.open {
            width: 100%;
            display: flex;
            padding: 24px 20px 40px 20px;
        }

        &.closed {
            display: none;
        }
    }

    ${above.desktop_s} {
        &.open {
            padding: 16px 0px 16px 32px;
            padding-right: 8.33%;
        }
    }
`;

const BlurredBackground = styled('div')`
    backdrop-filter: blur(0px);
    transition: backdrop-filter 0.2s 0.1s;

    &.open {
        width: 100%;
        height: 100vh;
        backdrop-filter: blur(${theme.blur.standard.mobile});

        ${above.tablet_s} {
            backdrop-filter: blur(${theme.blur.standard.desktop});
        }
    }
`;

const Navigation = ({navigation, isOpen, closeMobileNav, setSubMenu, openSubMenu, mouseEnterOpenMenu}) => {
    const { logout } = useAuth();

    const closeMenus = () => {
        setSubMenu(false);
        closeMobileNav();
    };

    const { allCmsApiProducts } = useStaticQuery(
        // We fetch width because it is an unused variable, so it is the smallest in bytes.
        graphql`
            query GetNumberOfProducts {
              allCmsApiProducts(filter: { id: { ne: "dummy" } }) {
                nodes {
                  width
                }
              }
            }
        `
    );
    const numberOfProducts = allCmsApiProducts.nodes.length;

    return(
        <NavigationWrapper className={isOpen ? 'open' : null}>
            <MainMenuWrapper>
                <LinksWrapper className={isOpen ? 'open' : null}>
                    {navigation.map((navData, index) => {
                        return (
                            <HeaderLinkWrapper
                                key={index}
                                className={openSubMenu === false ? 'subMenuOpen' : 'subMenuClosed'}
                                onClick={() => setSubMenu(index)}
                                onMouseEnter={() =>
                                    inBrowser && window.matchMedia('(min-width: 720px)').matches
                                        ? mouseEnterOpenMenu(index)
                                        : null
                                }
                            >
                                <HeaderLink color="black" src={navData.headerLink.url}>
                                    {navData.headerLink.text}
                                </HeaderLink>
                            </HeaderLinkWrapper>
                        );
                    })}
                    <MyPageWrapper className={openSubMenu === false ? 'subMenuOpen' : 'subMenuClosed'}>
                        {IsLoggedIn ? (
                            <>
                                <Link src="/account" onClick={closeMobileNav}>
                                    <Button small rounded>
                                        My page
                                    </Button>
                                </Link>
                                <LogoutButton small rounded outlined onClick={() => logout()}>
                                    Sign out
                                </LogoutButton>
                            </>
                        ) : (
                            <Link src="/login" onClick={closeMobileNav}>
                                <Button small rounded>
                                    Login
                                </Button>
                            </Link>
                        )}
                    </MyPageWrapper>
                </LinksWrapper>
            </MainMenuWrapper>
            <SubMenuWrapper className={openSubMenu !== false ? 'open' : 'closed'}>
                <SubMenuContent
                    className={openSubMenu !== false ? 'open' : 'closed'}
                    onMouseLeave={closeMenus}
                >
                    {openSubMenu !== false && (
                        <BackButton>
                            <Above breakpoint="mobile_l" onClick={closeMenus}>
                                <CrossButton />
                            </Above>
                            <StyledBelow breakpoint="tablet_s" onClick={() => setSubMenu(false)}>
                                <StyledArrow iconColor={theme.color.black} />
                                <BackButtonText as="span">Back</BackButtonText>
                            </StyledBelow>
                        </BackButton>
                    )}
                    <SubMenuLinkWrapper maxWidth={openSubMenu !== false && navigation[openSubMenu].menuModule}>
                        {openSubMenu !== false &&
                            navigation[openSubMenu].sidebarMenu.map((navData, index) => {
                                return (
                                    <StyledLinkWrapper key={index} onClick={navData.link.url ? closeMenus : null}>
                                        <StyledLink
                                            className={navData.link.url === '/all-reports' ? 'show-count' : null}
                                            count={numberOfProducts}
                                            src={navData.link.url}
                                        >
                                            {navData.link.text || navData.link.linkTitle}
                                        </StyledLink>
                                    </StyledLinkWrapper>
                                );
                            })}
                    </SubMenuLinkWrapper>
                    {openSubMenu !== false &&
                        navigation[openSubMenu].menuModule !== false &&
                        navigation[openSubMenu].menuModule.map(data =>
                            React.createElement(Modules(data.acfFcLayout), {
                                key: data.acfFcLayout,
                                module: data.acfFcLayout,
                                title: '',
                                data: data.data,
                                closeMenus,
                            })
                        )}
                </SubMenuContent>
                <BlurredBackground className={isOpen ? 'open' : null} onClick={closeMenus} />
            </SubMenuWrapper>
        </NavigationWrapper>
    );
};

Navigation.propTypes = {
    closeMobileNav: PropTypes.func.isRequired,
    isOpen: PropTypes.bool,
    mouseEnterOpenMenu: PropTypes.func.isRequired,
    navigation: PropTypes.arrayOf(PropTypes.object).isRequired,
    openSubMenu: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]).isRequired,
    setSubMenu: PropTypes.func.isRequired,
};

Navigation.defaultProps = {
    isOpen: false,
};

export default Navigation;